<template>
  <div class="hd-assessment-nursing-guidance-record">
    <VContainer fluid>
      <VForm>
        <VContainer>
          <VRow>
            <VCol cols="3">
              <VCard>TODO: 問卷列表、報告</VCard>
            </VCol>
            <VCol cols="9">
              <VCard>
                <VRow v-for="(v, k) in survey" :key="k">
                  <VCol>
                    <VCheckbox v-if="typeof v === 'boolean'" dense hide-details :label="k" />
                    <template v-else>
                      <VCheckbox dense hide-details :label="k" />
                      <VCol v-for="(v1, k1) in v" :key="k1" class="pl-8">
                        <VCheckbox dense hide-details :label="k1" />
                      </VCol>
                    </template>
                  </VCol>
                </VRow>
              </VCard>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDAssessmentNursingGuidanceRecord',
  data: () => ({
    survey: {
      飲食衛教指導: {
        高蛋白: false,
        高血磷: false,
        高血鉀: false,
        水分控制之重要性: false,
      },
      防災演練: false,
      防颱宣導: false,
      口頭告知HIV抽血項目: false,
      其他新增: false,
      當月抽血報告指導: false,
      季報告指導: false,
      新收案病患之血液透析腎友門診須知: false,
      新收案病患之末期腎臟衰竭治療模式: false,
      新收案病患之腎移植登記指導: false,
      衛教平台指導: false,
    },
  }),
});
</script>

<style lang="scss">
.hd-assessment-nursing-guidance-record {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 1200px;
        width: 1200px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
            .v-input {
              padding-top: 0px;
            }
            // .v-input--selection-controls {
            //   margin-top: -8px;
            // }
          }
        }
      }
    }
  }
}
</style>
